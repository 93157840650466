* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url("./assets/background.png") center;
  background-blend-mode: darken;
  /* Full height */
  min-height: 100vh;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: 'Jost', sans-serif;;
}
.top-bar-container {
  z-index: 200;
  width: 100%;
}
.general-info-wrapper {
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 10px;
  padding-top: 5px;
  justify-content: space-between;
}

.price-label-wrapper {
  display: flex;
  padding-bottom: 10px;
}

.mint-button {
  font-style: italic;
  font-weight: 400;
  font-size: 24px;
  /* or 172px */

  display: flex;
  align-items: center;

  background: linear-gradient(180deg, #FF0000 0%, #4B88FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all .2s ease-in-out;
}

.mint-button:hover {
  cursor: pointer;
  text-shadow: 0 0 20px white;
}

.mint-button-container {
  display: flex;
  flex-flow: row;
  gap: 20px;
  align-items: center;
  justify-items: center;
}

.mint-button-wrapper {
  display: flex;
  flex-flow: column;
}

.price-wrapper {
  display: flex;
  height: 20px;
  flex-flow: row;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

.price {
  border-radius: 4px;
  justify-content: center;
  flex-flow: row;
  text-align: center;
  display: flex;
  align-content: center;
  color: aliceblue;
  font-size: 24px;
  opacity: .2;
}

.number-of-tokens-wrapper {
  display: flex;
  flex-flow: row;
  gap: 10px;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.34rem;
  align-items: center;
  justify-content: center;
}

.number-of-tokens-wrapper button {
  background: transparent;
  color: aliceblue;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 9px;
}

.number-of-tokens-wrapper button:hover {
  background: orange;
  color: black;
  border-radius: 4px;
  cursor: pointer;
}

.whitelisted-user {
  font-size: 14px;
  font-style: italic;
  letter-spacing: 0.5px;
  padding: 40px 0;
  display: flex;
  justify-items: flex-start;
  align-items: flex-start;
}

.golden-flow-holder-text {
  color: #f2c94c;
  font-size: 64px;
  font-weight: 900;
}

.avax-text {
  opacity: 0.5;
  padding-left: 5px;
}

.sniped-container {
  display: flex;
  align-content: flex-end;
}

.mint-count-wrapper {
  text-shadow: 0px -4px 2px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  flex-grow: 0.5;
  align-self: flex-end;
  gap: 9px;
}

.sniped {
  font-size: 32px;
  color: #f6bcff;
  font-weight: 900;
}

.total-minted {
  font-style: italic;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;

  color: #A8A8A8;
}

.total-supply-text {
  font-size: 64px;
  color: #f2c94c;
  font-style: italic;
  letter-spacing: 36px;
  opacity: .3;
}

.to-go-text {
  color: #bcfbff;
  font-size: 24px;
  font-weight: 900;
}

.editions-container {
  color: #bcfbff;
  font-size: 64px;
  font-weight: 900;
  padding-bottom: 20px;
}

.show-wrapper {
  display: flex;
  flex-flow: column;
  gap: 6px;
}

.wallet-button-container {
  align-items: flex-end;
  width: 100%;
}

.wallet-button-container button {
  width: 100%;
  display: flex;
  align-items: center;
}
.content-wrapper {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.disabled-mint {
  opacity: .5;
}

.social-media-wrapper {
  display: flex;
  flex-flow: row;
  height: 15px;
  object-fit: cover;
  gap: 5px;
}

.show-alpha-wrapper {
  width: 150px;
  object-fit: cover;
}

.show-alpha-wrapper img {
  border: 1px solid white;
  border-radius: 4px;
}

.public-minting {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-items: flex-start;
  width: 100%;
}

.marketplace-logo-container {
  width: 30px;
  opacity: .5;
  transition: all .3s ease-out;
  padding-bottom: 20px;
}

.marketplace-logo-container:hover{
  opacity: 1;
  cursor: pointer;
}

.marketplace-wrapper {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  gap: 40px;
  width: 100%;
}

.balance {
  font-size: 24px;
  opacity: .3;
  padding: 20px 0;
}

.connect-wallet-text {
  font-size: 12px;
}

.paused-contract{
 font-size: 16px;
  color: brown;
}

